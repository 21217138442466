.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.panel1,
.panel2 {
  overflow: hidden;
}
.panel1 {
  padding-left: 3%;
}
.panel2 {
  padding-top: 5%;
}
.panel1.show,
.panel2.show {
  height: 100vh;
  transition: all 0.35s ease-in-out;
  padding-top: 4%;
}
.panel1.hide,
.panel2.hide {
  height: 0;
  transition: all 0.4s ease-in-out;
}